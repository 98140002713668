import { Directive, directive } from 'lit/directive.js'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'
import { asHTML } from '@prismicio/helpers'
import type { RichTextField } from '@prismicio/types'

class RichTextDirective extends Directive {
  render(value?: RichTextField, substitutionValues?: Record<string, string>) {
    let plainHTML = asHTML(value)

    if (substitutionValues) {
      Object.keys(substitutionValues).map((key) => {
        plainHTML =
          plainHTML?.replace(`{{${key}}}`, substitutionValues[key] ?? '') ??
          null
      })
    }

    return unsafeHTML(plainHTML ?? '')
  }
}

export const richText = directive(RichTextDirective)
