import { css } from 'lit'

export const styles = css`
  :host {
    --tml-footer-color: #fff;
    --tml-footer-dark-color: #000;
    --tml-footer-color-sitemap-subitem: rgba(255, 255, 255, 0.75);
    --tml-footer-color-disclaimer: rgba(255, 255, 255, 0.75);
    --tml-footer-background: #000000;
    --tml-footer-box-shadow: rgba(255, 255, 255, 0.5);

    color: var(--tml-footer-color);
  }

  * {
    font-family: europa, sans-serif;
  }

  button,
  a,
  a:hover,
  a:visited,
  a:active {
    color: var(--tml-footer-color);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  footer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 20px 0 20px;
  }

  footer::before {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--tml-footer-background);
  }

  footer::after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.15;
    background:
      linear-gradient(to top, rgba(0, 0, 0, 0), #0c0c0c),
      var(--tml-footer-background-image) bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tomorrowland-logo path {
    fill: var(--tml-footer-color);
  }

  .link {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 32px 0;
    padding: 20px;
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--tml-footer-dark-color) !important;
    background-color: var(--tml-footer-color);
    cursor: pointer;
    transition: box-shadow 0.15s ease;
  }

  .link:hover {
    color: var(--tml-footer-dark-color);
    box-shadow: 2px 5px 10px 0 var(--tml-footer-box-shadow);
  }

  .lines {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .lines p {
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.75;
    font-size: 12px;
    letter-spacing: 1.5px;
  }

  .socials__group {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 28px 0;
    padding: 0;
    list-style: none;
  }

  .socials__group a svg {
    height: 24px;
    transition: transform 0.3s ease-in-out;
    opacity: 0.8;
  }

  .socials__group a:hover svg {
    transform: scale(1.1);
    opacity: 1;
  }

  .line {
    width: 100%;
    height: 1px;
    opacity: 0.15;
    background: #000;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      var(--tml-footer-color) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .download-lines {
    margin-top: 32px;
  }

  .download-lines .line-left {
    margin-bottom: 10px;
  }

  .download-lines .line-right {
    position: absolute;
    bottom: -90px;
  }

  .downloads {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 16px 0 0;
    padding: 0;
    list-style: none;
  }

  .footer__disclaimer {
    margin: 40px 0 0;
    padding: 0 0 20px 0;
    color: var(--tml-footer-color-disclaimer);
    text-align: center;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .footer__disclaimer a {
    color: var(--tml-footer-color-disclaimer);
    display: inline-block;
  }

  .sitemap-grid {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .sitemap-grid button {
    width: 100%;
    text-align: start;
    border: 0;
    background: none;
    position: relative;
    margin: 0;
    padding: 12px 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.5px;
    cursor: pointer;
  }

  .sitemap-grid .links {
    display: flex;
    flex-direction: column;
  }

  .sitemap-grid .links.links-open {
    visibility: visible;
    height: 100%;
  }

  .sitemap-grid .links.links-close {
    visibility: hidden;
    height: 0;
  }

  .sitemap-grid .sitemap p {
    margin: 0;
  }

  .sitemap-grid .sitemap p::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    opacity: 0.15;
    background: #000;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      var(--tml-footer-color) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .sitemap-grid .sitemap p::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 4px;
    top: 50%;
    right: 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    transform: translateY(-2px) rotate(45deg);
  }

  .sitemap-grid .sitemap:first-of-type p::before {
    display: none;
  }

  .sitemap-grid li:not(.sitemap):not(.sitemap-links) {
    padding: 12px 0;
  }

  .sitemap-grid a {
    display: block;
    font-size: 12px;
    color: var(--tml-footer-color-sitemap-subitem);
    line-height: 1;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .sitemap-grid a:hover {
    text-decoration: underline;
  }

  .sitemap-grid .sitemap-links li {
    position: relative;
  }

  .sitemap-grid .sitemap-links li::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
    opacity: 0.15;
    background: #000;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      var(--tml-footer-color) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .sitemap-grid .sitemap-links a {
    position: relative;
    color: var(--tml-footer-color);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  @media (min-width: 768px) {
    footer {
      padding: 32px 40px 0 40px;
    }

    .lines {
      flex-direction: row;
    }

    .socials__group {
      margin: 32px 0;
    }

    .line {
      flex: 1;
      width: initial;
      margin: 0 40px 0 0;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        var(--tml-footer-color)
      );
    }

    .line.line-right {
      margin: 0 0 0 40px;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0),
        var(--tml-footer-color)
      );
    }

    .download-lines .line-left {
      margin-bottom: 0;
    }

    .download-lines .line-right {
      position: relative;
      bottom: initial;
    }

    .sitemap-grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, 1fr));
      gap: 40px;
      max-width: 1200px;
      margin: 32px 0 0 0;
    }

    .sitemap-grid button {
      cursor: default;
    }

    .sitemap-grid .links.links-open,
    .sitemap-grid .links.links-close {
      height: 100%;
      visibility: visible;
    }

    .sitemap-grid .sitemap p::before {
      display: none;
    }

    .sitemap-grid .sitemap p::after {
      display: none;
    }

    .sitemap-grid .sitemap-links li::after {
      display: none;
    }
  }

  @media (min-width: 992px) {
    footer {
      padding: 32px 80px 0 80px;
    }
  }
`
