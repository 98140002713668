import { LitElement, nothing, html } from 'lit'
import { property, state } from 'lit/decorators.js'
import logoSVG from '../../assets/logoSVG'
import logoWhiteSVG from '../../assets/logoWhiteSVG'
import facebookSVG from '../../assets/facebookSVG'
import instagramSVG from '../../assets/instagramSVG'
import twitterSVG from '../../assets/twitterSVG'
import youtubeSVG from '../../assets/youtubeSVG'
import tiktokSVG from '../../assets/tiktokSVG'
import androidBadgeSVG from '../../assets/androidBadgeSVG'
import appleBadgeSVG from '../../assets/appleBadgeSVG'
import { map } from 'lit/directives/map.js'
import { isFilledLinkToWebField } from '../../types/guards'
import { styles } from './styles'
import { richText } from '../../directives/RichTextDirective'
import { booleanConverter } from '../../utils/converters'
import discordSVG from '../../assets/discordSVG'
import { TmlFooterDocumentData } from '@repo/prismic-data/prismicio-types'

declare global {
  interface HTMLElementTagNameMap {
    'tml-footer': TMlFooter
  }
}

const socialIcons = {
  facebook: facebookSVG,
  instagram: instagramSVG,
  twitter: twitterSVG,
  youtube: youtubeSVG,
  tiktok: tiktokSVG,
  discord: discordSVG,
}

export class TMlFooter extends LitElement {
  static styles = styles

  @state()
  private _openSitemapId?: number

  @property()
  language?: string

  @property({
    converter: booleanConverter,
  })
  hasaccount?: boolean

  @property({
    converter: booleanConverter,
  })
  hassocials?: boolean

  @property({
    converter: booleanConverter,
  })
  hassitemap?: boolean

  @property({
    converter: booleanConverter,
  })
  hasdownloads?: boolean

  // Static data, of the default component. On compile a transformer will replace this with the actual data and will create multiple static components for it.
  @property({ attribute: false })
  data: Record<string, TmlFooterDocumentData> | undefined

  loadLanguage(locale: string) {
    this.language = locale
  }

  renderSocials(socials: TmlFooterDocumentData['socials']) {
    return html`<div class="lines">
      <div class="line line-left"></div>
      <ul class="socials__group">
        ${map(socials, ({ platform_icon, platform_link }) => {
          const isLink = isFilledLinkToWebField(platform_link)

          return isLink && platform_icon
            ? html`<li>
                <a href=${platform_link.url} target=${platform_link.target}>
                  ${socialIcons[platform_icon]}
                </a>
              </li>`
            : nothing
        })}
      </ul>
      <div class="line line-right"></div>
    </div>`
  }

  renderSitemap(slices: TmlFooterDocumentData['slices']) {
    return html`<ul class="sitemap-grid">
      ${map(slices, (slice, i) => {
        if (slice.slice_type === 'sitemap') {
          if (slice.variation === 'sitemapLinks') {
            return html`<li class="links sitemap-links">
              <ul>
                ${map(slice.items, ({ link, link_text }) => {
                  const isLink = isFilledLinkToWebField(link)

                  return isLink
                    ? html`<li>
                        <a href=${link.url} target=${link.target}
                          >${link_text}</a
                        >
                      </li>`
                    : nothing
                })}
              </ul>
            </li>`
          } else {
            return html`<li class="sitemap">
              <button
                @click=${() => {
                  if (this._openSitemapId === i) {
                    this._openSitemapId = undefined
                    return
                  }

                  this._openSitemapId = i
                }}
              >
                ${richText(slice.primary.sitemap_title)}
              </button>
              <ul
                class=${`links links-${
                  this._openSitemapId === i ? 'open' : 'close'
                }`}
              >
                ${map(slice.items, ({ link, link_text }) => {
                  const isLink = isFilledLinkToWebField(link)

                  return isLink
                    ? html`<li>
                        <a href=${link.url} target=${link.target}
                          >${link_text}</a
                        >
                      </li>`
                    : nothing
                })}
              </ul>
            </li>`
          }
        } else {
          return nothing
        }
      })}
    </ul>`
  }

  renderDownload(
    download_title: TmlFooterDocumentData['download_title'],
    download: TmlFooterDocumentData['download'],
  ) {
    return html`<div class="lines download-lines">
        <div class="line line-left"></div>
        <p>${download_title}</p>
        <div class="line line-right"></div>
      </div>
      <ul class="downloads">
        ${map(download, ({ device_platform, app_store_link }) => {
          const isLink = isFilledLinkToWebField(app_store_link)

          return isLink && device_platform
            ? html`<li>
                <a href=${app_store_link.url} target=${app_store_link.target}>
                  ${device_platform === 'android'
                    ? androidBadgeSVG
                    : appleBadgeSVG}
                </a>
              </li>`
            : nothing
        })}
      </ul>`
  }

  render() {
    const data = this.data?.[this.language || 'en-us']
    if (!data) return nothing

    return html`<footer>
      <a href="https://www.tomorrowland.com/">
        ${data.logo === 'winter' ? logoWhiteSVG : logoSVG}
      </a>
      ${this.hasaccount && isFilledLinkToWebField(data.my_account_link)
        ? html`<a
            class="link"
            href=${data.my_account_link.url}
            target=${data.my_account_link.target}
          >
            ${data.my_account_link_text}
          </a>`
        : nothing}
      ${this.hassocials && data.socials.length > 0
        ? this.renderSocials(data.socials)
        : nothing}
      ${this.hassitemap && data.slices.length > 0
        ? this.renderSitemap(data.slices)
        : nothing}
      ${this.hasdownloads && data.download.length > 0
        ? this.renderDownload(data.download_title, data.download)
        : nothing}
      <div class="footer__disclaimer">
        ${richText(data.copyright, {
          year: new Date().getFullYear().toString(),
        })}
      </div>
    </footer>`
  }
}

// Define only if not already exists
if (!window.customElements.get('tml-footer')) {
  window.customElements.define('tml-footer', TMlFooter)
}
