import { LinkType } from '@prismicio/types'
import type { LinkField, FilledLinkToWebField } from '@prismicio/types'
import type {
  NavigationItemSlice,
  NavigationItemSliceDefaultItem,
} from '@repo/prismic-data/prismicio-types'

export const isFilledLinkToWebField = (
  link: LinkField,
): link is FilledLinkToWebField => {
  return link.link_type === LinkType.Web && 'url' in link && !!link.url
}

export const isSubNavigationItem = (
  item: NavigationItemSliceDefaultItem | NavigationItemSlice,
): item is NavigationItemSliceDefaultItem => {
  return (
    'sub_navigation_item_text' in item && 'sub_navigation_item_link' in item
  )
}
